import React from "react";

import brand1 from "../../assets/image/home-1/company-logo/company-logo-1.webp";
import brand2 from "../../assets/image/home-1/company-logo/company-logo-2.webp";
import brand3 from "../../assets/image/home-1/company-logo/neem-logo-clr_180x@2x.avif";
import brand4 from "../../assets/image/home-1/company-logo/balmista.webp";
import brand5 from "../../assets/image/home-1/company-logo/golf-course-flyovers.webp";

import Slider from 'react-slick';

const SingleBrand = ({image, link, name}) => {
  return (
    <>
      {/* Single Brand */}
      <div className="single-brand-logo">
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          tabIndex="-1">
          <img src={image} className="w-100" alt={name} data-aos="fade-in" data-aos-once="true" />
        </a>
      </div>
      {/* Single Brand */}
    </>
  );
}

const Company = ({ className, ...rest }) => {
    const items = [
      {image:brand3, link:"https://www.neemlondon.com/", name:"Neem"},
      {image:brand5, link:"https://www.golfcourseflyovers.co/", name:"Golf Course Flyovers"},
      {image:brand4, link:"https://balmista.co.uk/", name:"Balmista"},
      {image:brand1, link:"https://www.indoordronetours.com", name:"Indoor Drone Tours"},
      {image:brand2, link:"https://cosmiccrystals.co.uk/pages/interactive", name:"Cosmic Crystals"}
    ];

    const slickSettings = {
      autoplay: true,
      slidesToShow: 5,
      arrows: false,
      infinite: false,
      dots: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 468,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };    
  return (
    <>
      {/* Company Section */}
      <div className={className} {...rest}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mb-lg-8 text-center text-lg-center">
                <h4 className="h3 mb-0">
                  Some Of Our Clients
                </h4>
              </div>
            </div>
          </div>
          {/* Brand Logos */}
          <div className="testimonial-one">
          <Slider
                {...slickSettings}
                css={`
                  .slick-dots {
                    display: flex !important;
                  }
                `}
              >            
          {items.map(({ image, link, name}, index) => (
              <SingleBrand image={image} name={name} link={link} key={index} />
          ))}
          </Slider>
          </div>
        </div>
      </div>
      {/* End Company Section */}
    </>
  );
};

export default Company;
